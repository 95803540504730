import React, {FC, useContext, useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {AuthContext} from "contexts";
import {GenderName, Genders, isDeleteProfileAndGiftsSupported} from "enums";
import {
    AbuseWord,
    abuseWordsService,
    ActionTypes,
    ActionTypesLabels,
    PatternTypes,
    PatternTypesLabels
} from "services/abuseWordsService";

const DEFAULT_WORD: AbuseWord = {
    ruleName: '',
    allowSeparateWord: true,
    patternType: PatternTypes.Any,
    actionType: ActionTypes.AdminCheck,
    genders: [],
    pattern: '',
    allowDelimiters: true,
    allowMultiline: true,
    allowIgnoreCase: true,
    id: 0,
    tag: null,
    language: null
}

interface Props {
    editingWord: AbuseWord | null;
    onReset: (didChange?: boolean) => void;
}

export const AbuseWordModal: FC<Props> = ({editingWord, onReset}) => {
    const {env, user} = useContext(AuthContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [word, setWord] = useState<AbuseWord>(DEFAULT_WORD);

    const handleClose = (didChange?: boolean) => {
        onReset(didChange);
        setWord(DEFAULT_WORD);
        setIsModalVisible(false);
    }

    const handleSave = async () => {
        if (!env) {
            return;
        }
        if (word.id) {
            await abuseWordsService.update(word, env)
        } else {
            await abuseWordsService.add(word, env)
        }
        handleClose(true);
    }

    useEffect(() => {
        if (editingWord) {
            setWord(editingWord);
            setIsModalVisible(true);
        }
    }, [editingWord]);

    if (!user?.isSuperAdmin || !env || !isDeleteProfileAndGiftsSupported(env)) {
        return null;
    }

    return <>
        <Button
            onClick={() => setIsModalVisible(true)}
            variant="outline-primary">
            Добавить Abuse Слово
        </Button>
        <Modal show={isModalVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Добавить Abuse Слово</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Название</Form.Label>
                        <Form.Control type="text" placeholder="Тег: Название (Язык)"
                                      value={word.ruleName}
                                      onChange={e => setWord(w => ({...w, ruleName: e.target.value}))}/>
                        <small id="passwordHelpBlock" className="form-text text-muted">
                            Формат: <span className="fst-italic">Тег: Название (Язык)</span>
                        </small>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Тип проверки</Form.Label>
                        <Form.Select defaultValue={word.patternType} onChange={e => {
                            setWord(w => ({...w, patternType: +e.target.value}))
                        }}>
                            {Object.values(PatternTypes).filter(v => typeof (v) === 'number').map(v =>
                                <option value={v} key={v}>{PatternTypesLabels[+v as PatternTypes]}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="col-sm-2 col-form-label">
                            Гендер
                        </Form.Label>
                        <div className="col-sm-10">
                            {Object.values(Genders).map(gender =>
                                <div key={gender} className="form-check">
                                    <input className="form-check-input"
                                           type="checkbox" value="" id={gender}
                                           checked={word.genders.includes(gender)}
                                           onChange={(e) => setWord(prev => prev.genders.includes(gender) ? {
                                               ...prev,
                                               genders: prev.genders.filter(g => g !== gender)
                                           } : {...prev, genders: [...prev.genders, gender]})}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        {GenderName[gender]}
                                    </label>
                                </div>)}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Действие</Form.Label>
                        <Form.Select defaultValue={word.actionType} value={word.actionType} onChange={e => {
                            setWord(w => ({...w, actionType: (+e.target.value)}))
                        }}>
                            {Object.values(ActionTypes).filter(v => typeof (v) === 'number').map(v =>
                                <option value={v} key={v}>{ActionTypesLabels[+v as ActionTypes]}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Паттерн</Form.Label>
                        <Form.Control type="text" placeholder="Abuse Слово"
                                      value={word.pattern}
                                      onChange={e => setWord(w => ({...w, pattern: e.target.value}))}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Разделители</Form.Label>
                        <div>
                            <input className="form-check-input"
                                   type="checkbox" value=""
                                   checked={word.allowDelimiters}
                                   onChange={(e) => setWord(w => ({...w, allowDelimiters: e.target.checked}))}/>
                            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
                                Могут ли быть различные разделители в слове (пробелы, знаки препинания и тп)
                            </label>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Многострочный</Form.Label>
                        <div>
                            <input className="form-check-input"
                                   type="checkbox" value=""
                                   checked={word.allowMultiline}
                                   onChange={(e) => setWord(w => ({...w, allowMultiline: e.target.checked}))}/>
                            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
                                Может ли быть разбито на несколько строк
                            </label>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Отдельное слово</Form.Label>
                        <div>
                            <input className="form-check-input"
                                   type="checkbox" value=""
                                   checked={word.allowSeparateWord}
                                   onChange={(e) => setWord(w => ({...w, allowSeparateWord: e.target.checked}))}/>
                            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
                                Проверять паттерн только отдельным словом
                            </label>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Игнорировать регистр</Form.Label>
                        <div>
                            <input className="form-check-input"
                                   type="checkbox" value=""
                                   checked={word.allowIgnoreCase}
                                   onChange={(e) => setWord(w => ({...w, allowIgnoreCase: e.target.checked}))}/>
                            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
                                Игнорировать ли регистр слова (case insensitive)
                            </label>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    Закрыть
                </Button>
                <Button disabled={!word.ruleName || !word.pattern || !word.genders.length} variant="primary"
                        onClick={handleSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

