import {VerificationRejectReasons, VerificationRejectReasonsLabels} from "enums/VerificationRejectReasons";
import {usersService, verificationsService} from "services";
import {Envs, EnvSetup} from "enums/Envs";
import {ModerationUser} from "interfaces";
import {ReasonsSelect} from "components";
import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Apps} from "enums";
import {Events, eventTracker, ModerationSources, ReviewTypes} from "utils/eventTracker";


const MySwal = withReactContent(Swal)

export const useVerificationProcessing = (env?: EnvSetup, user?: ModerationUser) => {
    const sendVerification = async (isAccepted: boolean, reason?: VerificationRejectReasons) => {
        if (!env || !user) {
            return false;
        }

        eventTracker.track(Events.review, {
            ...eventTracker.getReviewParams(user, ReviewTypes.Verification, ModerationSources.Verification),
            wasVerified: isAccepted,
            denyReason: reason
        })
        await verificationsService.verify(env, user?.id, isAccepted, reason);
        await usersService.dislike(user?.id, env)
        return true
    }
    const openVerifyDialog = async (isAccepted: boolean): Promise<{
        isSuccess: boolean,
        reason?: VerificationRejectReasons
    }> => {
        if (!env) {
            return {isSuccess: false};
        }

        if (user?.id) {
            if (isAccepted || (env.env === Envs.Prod && env.app !== Apps.Flava)) {
                const isSuccess = await sendVerification(isAccepted)
                return {isSuccess}
            } else {
                let reason: VerificationRejectReasons | undefined
                const result = await MySwal.fire({
                    title: 'Причина отклонения',
                    icon: 'question',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Отмена",
                    html: <ReasonsSelect
                        all={Object.values(VerificationRejectReasons)}
                        labels={VerificationRejectReasonsLabels}
                        onSelect={async r => {
                            reason = r
                            MySwal.close()
                            return await sendVerification(isAccepted, r)
                        }}/>
                })
                return {isSuccess: !(result.isDismissed && result.dismiss === Swal.DismissReason.cancel), reason}
            }
        }
        return {isSuccess: false};
    };

    return {openVerifyDialog}
}