import {ModerationUser} from 'interfaces';
import React, {FC, ReactNode, useContext, useEffect, useRef} from 'react';
import {usersService, verificationsService} from 'services';
import {AuthContext} from 'contexts';
import {ModerationInfo, ModerationVerification} from "./components";
import {Button} from "react-bootstrap";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import {BanReasons, BanReasonsLabels, isRequiredVerificationSupprted, isShadowBanSupported, UserStatuses} from "enums";
import {ReasonsSelect} from "components/ReasonsSelect";
import {useHotkeys} from "react-hotkeys-hook";
import {ModerationPhotos} from "components/ModerationUserCard/ModerationPhotos/ModerationPhotos";
import {
    ModerationAttractiveFeatures
} from "components/ModerationUserCard/ModerationPhotos/ModerationAttractiveFeatures";
import {FaBan, FaTheaterMasks} from "react-icons/fa";
import {BiHide} from "react-icons/bi";
import {AuditTypes, EventsPerModerationSource, eventTracker, ModerationSources, ReviewTypes} from "utils/eventTracker";
import {statsStore} from "stores/statsStore";

const MySwal = withReactContent(Swal)

interface UserProps {
    canAskVerification?: boolean;
    hideVerificationPhoto?: boolean;
    header?: ReactNode;
    children?: ReactNode;
    user: ModerationUser;
    onUserChange: (user: ModerationUser) => void;
    greenButton?: {
        text: string;
        onClick: () => void;
    };
    redButton?: { text: string, onClick: () => void }
    leftButtons?: ReactNode;
    buttons?: ReactNode;
    topButtons?: ReactNode;
    moderationSource: ModerationSources;
    onPostponeReview?: () => void
}

export const ModerationUserCard: FC<UserProps> = ({
                                                      canAskVerification = true,
                                                      header,
                                                      user,
                                                      greenButton,
                                                      children,
                                                      onUserChange,
                                                      buttons,
                                                      redButton,
                                                      leftButtons,
                                                      moderationSource,
                                                      onPostponeReview,
                                                      hideVerificationPhoto,
                                                      topButtons
                                                  }) => {
    const ref = useRef<HTMLDivElement>(null)
    const {env} = useContext(AuthContext);

    const canAskToVerify = canAskVerification && !user.isVerified && env && isRequiredVerificationSupprted(env.app, user.version);


    const ban = async (isShadowBan: boolean) => {
        if (!env || !user?.id) {
            return;
        }

        await MySwal.fire({
            title: isShadowBan ? 'Причина теневого бана' : 'Причина бана',
            icon: isShadowBan ? 'warning' : 'question',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Отмена",
            html: <ReasonsSelect
                all={Object.values(BanReasons)}
                labels={BanReasonsLabels}
                onSelect={async reason => {
                    MySwal.close()
                    await usersService.ban(user?.id, reason, moderationSource, isShadowBan, env);
                    const params = moderationSource === ModerationSources.Audit && statsStore.selectedAdmin
                        ? eventTracker.getAuditParams(statsStore.selectedAdmin, user, AuditTypes.Ban, moderationSource)
                        : eventTracker.getReviewParams(user, ReviewTypes.Ban, moderationSource);
                    eventTracker.track(
                        EventsPerModerationSource[moderationSource],
                        {
                            ...params,
                            reason,
                            source: moderationSource,
                            isShadowBan
                        });
                    onUserChange && onUserChange({
                        ...user,
                        isBanned: true,
                        wasReviewed: true,
                        reviewedDate: new Date(),
                        status: isShadowBan ? UserStatuses.ShadowBan : UserStatuses.Banned
                    });
                }}/>
        })
    };

    const temporaryUnban = async () => {
        if (!env) {
            return;
        }

        const params = moderationSource === ModerationSources.Audit && statsStore.selectedAdmin
            ? eventTracker.getAuditParams(statsStore.selectedAdmin, user, AuditTypes.TemporaryUnban, moderationSource)
            : eventTracker.getReviewParams(user, ReviewTypes.TemporaryUnban, moderationSource);
        eventTracker.track(EventsPerModerationSource[moderationSource], params)

        user?.id && (await usersService.temporaryUnban(user?.id, env));
        onUserChange && onUserChange({
            ...user,
            wasReviewed: true,
            reviewedDate: new Date(),
            isBanned: false,
            status: UserStatuses.TemporaryUnbanned
        });
    };

    const forceVerify = async () => {
        if (!env) {
            return;
        }

        user?.id && (await verificationsService.force(env, user?.id));
        const params = moderationSource === ModerationSources.Audit && statsStore.selectedAdmin
            ? eventTracker.getAuditParams(statsStore.selectedAdmin, user, AuditTypes.ForceVerification, moderationSource)
            : eventTracker.getReviewParams(user, ReviewTypes.ForceVerification, moderationSource);
        eventTracker.track(EventsPerModerationSource[moderationSource], params)
        onUserChange && onUserChange({
            ...user, wasReviewed: true,
            reviewedDate: new Date(),
            status: UserStatuses.NeedsVerification
        });
    }

    const postponeReview = async () => {
        if (!env || !onPostponeReview) {
            return;
        }

        const params = moderationSource === ModerationSources.Audit && statsStore.selectedAdmin
            ? eventTracker.getAuditParams(statsStore.selectedAdmin, user, AuditTypes.Postpone, moderationSource)
            : eventTracker.getReviewParams(user, ReviewTypes.Postpone, moderationSource);
        eventTracker.track(EventsPerModerationSource[moderationSource], params)
        user?.id && (await usersService.postponeReview(env, user?.id));
        onPostponeReview()
    }

    const simpleBan = async () => {
        return ban(false)
    }

    useEffect(() => {
        ref.current?.scroll({top: 0})
    }, [user.id])

    useHotkeys('shift+x', () => simpleBan(), [user?.id])

    return <>
        <div
            ref={ref}
            key={user.id + user.status + user.name}
            className="card border-0 shadow-none d-flex flex-column align-items-center align-items-md-end w-100"
            style={{maxHeight: '100vh', paddingBottom: 300, overflowX: 'hidden', overflowY: 'auto'}}>
            {header}
            <div className="row w-100 g-0">
                <div className="col-12 col-sm-5 container-fluid g-0">
                    <div className="row mx-1">
                        <ModerationPhotos user={user} onUserChange={onUserChange}/>
                    </div>

                    <ModerationAttractiveFeatures user={user} onUserChange={onUserChange}/>
                    <ModerationVerification hideVerificationPhoto={hideVerificationPhoto} user={user}
                                            onUserChange={onUserChange}/>
                </div>
                <div className="col-12 col-sm-7 g-0 card-body px-0">
                    <ModerationInfo user={user}/>
                </div>
            </div>
            <div className="d-flex flex-column w-100 mt-2">
                {children}
            </div>
        </div>
        <div
            className="position-absolute d-flex flex-column align-items-end bg-white py-2 pb-lg-4 px-2 px-lg-4 rounded"
            style={{bottom: 0, right: 0}}>
            <div className="mb-1 mb-lg-2 mx-lg-2 d-flex flex-row justify-content-end" style={{gap: 6}}>
                {topButtons}
                {isShadowBanSupported(env) &&
                    <Button onClick={() => ban(true)} variant="dark" className="d-flex flex-row align-items-center">
                        <BiHide style={{marginRight: 4}}/>
                        В теневой бан
                    </Button>}
                {canAskToVerify &&
                    <Button disabled={user.status === UserStatuses.NeedsVerification} onClick={forceVerify}
                            variant="warning" className="d-flex flex-row align-items-center">
                        <FaTheaterMasks style={{marginRight: 4}}/>
                        Запросить верификацию
                    </Button>}
                {onPostponeReview &&
                    <Button onClick={postponeReview} variant="light">
                        Посмотреть позже
                    </Button>}
            </div>
            <div className="d-flex flex-row mt-1 mt-lg-2 mx-lg-2 justify-content-end" style={{gap: 6}}>
                {leftButtons}
                <Button onClick={redButton?.onClick ?? simpleBan}
                        disabled={user.isBanned}
                        variant="danger"
                        className="d-flex flex-row align-items-center">
                    <FaBan style={{marginRight: 4}}/>
                    {redButton?.text ?? "Заблокировать"}
                </Button>
                {user.isBanned &&
                    <Button onClick={temporaryUnban} variant="outline-success">
                        Временно Разблокировать
                    </Button>
                }
                {greenButton && (
                    <Button
                        onClick={greenButton.onClick}
                        variant="success">
                        {greenButton.text}
                    </Button>
                )}
                {buttons}
            </div>
        </div>
    </>
};
