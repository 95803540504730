import React, {FC, useContext} from 'react';
import {ModerationUser} from "interfaces";
import {ModerationPic} from "./ModerationPic";
import {getPhotoInfoFromTag, isDeleteAllAvailable, isReviewPhoto, UserReviewTags} from "enums";
import {ModerationPhotoProvider} from "components/ModerationUserCard/ModerationPhotos/PhotoProvider";
import {FaRegTrashAlt} from "react-icons/fa";
import {usePhotoManagement} from "components/ModerationUserCard/ModerationPhotos/usePhotoManagement";
import {AuthContext} from "contexts";
import {Events, eventTracker, ModerationSources} from "utils/eventTracker";


interface Props {
    user: ModerationUser
    onUserChange: (user: ModerationUser) => void;
}

export const ModerationPhotos: FC<Props> = ({user, onUserChange}) => {
    const {env} = useContext(AuthContext);
    const photos = user.photos ?? [];
    const photoManager = usePhotoManagement()

    if (!photos.length) {
        return <div className="col-12 col-md-6">Нет фото</div>
    }

    const onPhotoDeleted = (key: string) => {
        const photo = user.photos?.find(p => p.key === key);
        if (photo) {
            const eventParams = eventTracker.getReviewParams(user, null, ModerationSources.Moderation);
            eventTracker.track(Events.photo_delete, eventTracker.getPhotoParams(photo, eventParams))
        }
        onUserChange({...user, photos: photos.filter(p => p.key !== key)})
    }

    const onSensitiveTagChanged = (key: string, isSensitive: boolean) => {
        const photo = user.photos?.find(p => p.key === key);
        if (photo) {
            const eventParams = eventTracker.getReviewParams(user, null, ModerationSources.Moderation);
            eventTracker.track(Events.photo_update, eventTracker.getPhotoParams(photo, {
                ...eventParams,
                type: isSensitive ? 'added_Sensitive' : 'removed_Sensitive'
            }))
        }
        onUserChange({...user, photos: photos.map(p => p.key === key ? {...p, isSensitive} : p)})
    }

    const onNoFaceChanged = (key: string, newHasNoFace: boolean) => {
        const photo = user.photos?.find(p => p.key === key);
        if (photo) {
            const eventParams = eventTracker.getReviewParams(user, null, ModerationSources.Moderation);
            eventTracker.track(Events.photo_update, eventTracker.getPhotoParams(photo, {
                ...eventParams,
                type: newHasNoFace ? 'added_NoFace' : 'removed_NoFace'
            }))
        }
        onUserChange({
            ...user,
            reviewTags: newHasNoFace ?
                [...user.reviewTags, {tag: UserReviewTags.NoFace, info: key}]
                : user.reviewTags.filter(t => !(t.tag === UserReviewTags.NoFace && t.info === key))
        })
    }

    return <>
        {user.photos?.length && isDeleteAllAvailable(env) &&
            <div>
                <button type="button"
                        className="btn btn-outline-secondary btn-sm d-flex flex-row align-items-center"
                        onClick={async () => {
                            const result = await photoManager.onDeleteAllPics(user.id, user.photos?.map(p => p.key))
                            const eventParams = eventTracker.getReviewParams(user, null, ModerationSources.Moderation);
                            eventTracker.track(Events.profile_open, eventParams)
                            if (user.photos) {
                                for (let i = 0; i < user.photos.length; i++) {
                                    eventTracker.track(Events.photo_delete, eventTracker.getPhotoParams(user.photos[i], eventParams))
                                }
                            }
                            if (result) {
                                onUserChange({
                                    ...user,
                                    photos: [],
                                })
                            }
                        }}>
                    <FaRegTrashAlt size={14} style={{marginRight: 4}}/>
                    Удалить все фото
                </button>
            </div>
        }
        <ModerationPhotoProvider
            userId={user.id}
            reviewTags={user.reviewTags}
            photos={photos}
            onPhotoDeleted={onPhotoDeleted}
            onSensitiveTagChanged={onSensitiveTagChanged}>
            {photos.map(p => {
                if (!p || !p.image || !p.image.length) {
                    return null
                }
                const photoTags = user.reviewTags.filter(rt => isReviewPhoto(rt.tag) && rt.info && getPhotoInfoFromTag(rt.info, rt.tag)?.key === p.key);
                const duplicates = user.photosWithDuplicateHashes?.find(d => d.photoKey === p.key)?.usersWithDuplicate ?? [];
                const hasNoFace = photoTags.find(t => t.tag === UserReviewTags.NoFace);
                return <div key={p.image[0].link} className="col-6 col-xl-6 align-self-center">
                    <ModerationPic
                        userId={user.id}
                        pic={p}
                        onSensitiveTagChanged={onSensitiveTagChanged}
                        onPhotoDeleted={onPhotoDeleted}
                        reviewTags={photoTags}
                        duplicateHashes={duplicates}
                        onNoFaceChanged={onNoFaceChanged}
                        hasNoFace={!!hasNoFace}
                    />
                </div>
            })}
        </ModerationPhotoProvider>
    </>
}

