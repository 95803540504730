import React, {useContext, useEffect, useRef, useState} from 'react';
import {usersService} from 'services';
import {ModerationUser} from 'interfaces';
import {AuthContext} from 'contexts';
import {ModerationUserCard} from 'components';
import {Button} from "react-bootstrap";
import {Genders, UserStatuses} from "enums";
import {observer} from "mobx-react";
import {settingsStore} from "stores";
import {AdminsSelect} from "./components";
import {statsStore} from "stores/statsStore";
import {
    AuditTypes,
    Events,
    EventsPerModerationSource,
    eventTracker,
    ModerationSources,
    ReviewTypes
} from "utils/eventTracker";


export const ReviewedUsersPage = observer(() => {
    const hasMore = useRef(true);

    const {app, env} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [users, setUsers] = useState<ModerationUser[]>([]);

    const user = users[currentIndex];

    const next = async (isChanged: boolean) => {
        if (!app || !statsStore.selectedAdmin) {
            return;
        }

        if (!isChanged) {
            eventTracker.track(Events.audit, eventTracker.getAuditParams(statsStore.selectedAdmin, user, AuditTypes.Approve, ModerationSources.Audit));
        }

        setCurrentIndex(prev => prev + 1)

        if (statsStore.selectedAdmin?.userKey && users.length - currentIndex < 10 && hasMore.current && !isLoading) {
            setIsLoading(true);
            const newUsers = await usersService.getReviewedPage(
                app,
                statsStore.selectedAdmin?.userKey,
                users.length,
                statsStore.selectedGender
            );
            hasMore.current = !!newUsers?.length && newUsers.length > 10;
            setUsers(prev => [...prev, ...(newUsers ?? [])]);
            setIsLoading(false);
        }
    };

    const back = () => {
        setCurrentIndex(prev => prev - 1)
    }

    const onUserChange = async (u: ModerationUser) => {
        if (user.status !== u.status && u.status !== UserStatuses.Active) {
            await next(true)
        }
        setUsers(pus => pus.map(pu => pu.id === u.id ? u : pu))
    }

    const reset = async (id?: string, gender?: Genders) => {
        const admin = id ?? statsStore.selectedAdmin?.userKey;
        if (!admin || !app) {
            return
        }
        const newUsers = await usersService.getReviewedPage(app, admin, 0, gender);
        newUsers && setUsers(newUsers);
        setIsLoading(false);
        setCurrentIndex(0)
    }

    const unblock = async () => {
        if (!env || !user) {
            return;
        }

        eventTracker.track(EventsPerModerationSource[ModerationSources.Audit], eventTracker.getReviewParams(user, ReviewTypes.Unban, ModerationSources.Audit))
        user?.id && (await usersService.unban(user.id, env));

        setUsers(pus => pus.map(pu => pu.id === user.id ? {
            ...pu, wasReviewed: true,
            reviewedDate: new Date(),
            isBanned: false,
            status: UserStatuses.Active
        } : pu));
        next(true)
    };

    useEffect(() => {
        if (!app) {
            return;
        }


        setIsLoading(true);
        setTimeout(reset, 300);

    }, [app, settingsStore.selectedCountry?.countryCode]);

    useEffect(() => {
        if (user?.id) {
            eventTracker.track(Events.profile_open, eventTracker.getReviewParams(user, null, ModerationSources.Audit))
        }
    }, [user?.id]);

    if (isLoading || !user) {
        const admin = statsStore.selectedAdmin?.userKey;
        return <div className="m-2 m-lg-3">
            <AdminsSelect onChange={reset}/>
            {admin ? isLoading ? 'Загружается...' : 'Это все' : 'Требуется выбрать админа'}
        </div>
    }

    return <div className="m-2 m-lg-3">
        <AdminsSelect onChange={reset}>
            <div className="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <div className={`alert ${currentIndex < 50 ? 'alert-light' : 'alert-danger'} border`}>
                    <div><strong>Просмотрено за эту сессию
                        у {statsStore.selectedAdmin?.name}: {currentIndex}</strong></div>
                </div>
            </div>
        </AdminsSelect>
        <ModerationUserCard
            user={user}
            onUserChange={onUserChange}
            greenButton={{text: 'Следующий', onClick: () => next(false)}}
            moderationSource={ModerationSources.Audit}
            leftButtons={
                <>
                    <Button disabled={currentIndex === 0} variant="secondary" className="mx-1"
                            onClick={back}>
                        Назад
                    </Button>
                    <Button disabled={!user.isBanned} variant="success" className="mx-1"
                            onClick={unblock}>
                        Разблокировать
                    </Button>
                </>
            }
        />
    </div>;
});
