import React, {FC} from "react";
import {ActionTypes, ActionTypesLabels} from "services/abuseWordsService";
import {UserStatusEmojis, UserStatuses} from "enums";

export const AbuseActionTypeTag: FC<{ action: ActionTypes }> = ({action}) => {
    const value: ActionTypes = action;
    const emoji: Record<ActionTypes | number, string> = {
        [ActionTypes.Ban]: UserStatusEmojis[UserStatuses.Banned],
        [ActionTypes.Suspicious]: UserStatusEmojis[UserStatuses.Suspicious],
        [ActionTypes.Hide]: '🙈',
        [ActionTypes.AdminCheck]: '',
        [ActionTypes.NeedsVerification]: UserStatusEmojis[UserStatuses.NeedsVerification],
        1: '',
    }
    const string = `${emoji[value]} ${ActionTypesLabels[value]}`
    return (
        <span className={`badge bg-secondary`}>{string}</span>
    );
}