import {request, UserPayloadBloom, UserPayloadFlava, UserPayloadInstinct, UserPayloadPortal} from 'services';
import {Apps} from 'enums';

interface LoginPayloadInstinct {
    jToken: string;
    rToken: string;
    loggedInUser: UserPayloadInstinct;
}


interface LoginPayloadBloom {
    jwt: string;
    refreshToken: string;
    user: UserPayloadBloom;
}

interface LoginPayloadSpark {
    token: string;
    refreshToken: string;
    sparker: UserPayloadPortal;
}

interface LoginPayloadFlava {
    jwt: string;
    refreshToken: string;
    profile: UserPayloadFlava;
}

export const getRefreshUrl = (app: Apps) => app === Apps.Flava || app === Apps.Bloom
    ? 'sign-in/refresh'
    : app === Apps.Instinct || app === Apps.Spark ? 'login/refresh' : ''

export const loginService = {
    login: async (token: string, app: Apps) => {
        if (app === Apps.Instinct) {
            const response = await request<LoginPayloadInstinct>({
                url: 'login/support',
                method: 'POST',
                data: {accessToken: token, languageCode: 'en'},
            });
            return {
                user: response?.loggedInUser ? {
                    id: response?.loggedInUser.id,
                    name: response?.loggedInUser.name,
                    isSuperAdmin: response?.loggedInUser.isSuperAdmin,
                } : undefined,
                token: response?.jToken,
                refresh: response?.rToken
            };
        } else if (app === Apps.Bloom) {
            const response = await request<LoginPayloadBloom>({
                url: 'sign-in/apple',
                method: 'POST',
                data: {token},
            });
            return {
                user: response?.user ? {
                    id: response?.user?.id,
                    name: response?.user?.name,
                    isSuperAdmin: response?.user.isSuperAdmin,
                } : undefined,
                token: response?.jwt,
                refresh: response?.refreshToken
            };
        } else if (app === Apps.Spark) {
            const response = await request<LoginPayloadSpark>({
                url: 'enter/support',
                method: 'POST',
                data: {enterCode: token},
            });
            return {
                user: response?.sparker ? {
                    id: response?.sparker?.id,
                    name: response?.sparker?.name,
                    isSuperAdmin: response?.sparker.isSuperSupport,
                } : undefined,
                token: response?.token,
                refresh: response?.refreshToken
            };
        } else {
            const response = await request<LoginPayloadFlava>({
                url: 'sign-in/support',
                method: 'POST',
                data: {accessToken: token, localeCode: 'ru'},
            });
            return {
                user: response?.profile ? {
                    id: response?.profile?.id,
                    name: response?.profile?.firstname,
                    isSuperAdmin: response?.profile.isSuperAdmin,
                } : undefined,
                token: response?.jwt,
                refresh: response?.refreshToken
            };
        }
    },
    refresh: async (token: string, userId: string, app: Apps) => {
        const url = getRefreshUrl(app)

        if (app === Apps.Instinct) {
            const response = await request<LoginPayloadInstinct>({
                url: url,
                method: 'POST',
                data: {rToken: token, id: userId},
            });
            return {user: response?.loggedInUser.id, token: response?.jToken, refresh: response?.rToken};
        } else if (app === Apps.Flava) {
            const response = await request<LoginPayloadFlava>({
                url: url,
                method: 'POST',
                data: {refreshToken: token, id: userId},
            });
            return {user: response?.profile.id, token: response?.jwt, refresh: response?.jwt};
        } else if (app === Apps.Bloom) {
            const response = await request<LoginPayloadBloom>({
                url: url,
                method: 'POST',
                data: {refreshToken: token, userId},
            });
            return {user: response?.user.id, token: response?.jwt, refresh: response?.refreshToken};
        } else if (app === Apps.Spark) {
            const response = await request<LoginPayloadSpark>({
                url: url,
                method: 'POST',
                data: {refreshToken: token, userKey: userId},
            });
            return {user: response?.sparker.id, token: response?.token, refresh: response?.refreshToken};
        } else {
            alert("loginServices.refresh Not implemented!")
        }

    },
    getUser: async (app: Apps) => {
        const url = app === Apps.Instinct || app === Apps.Flava ? 'profile' : app === Apps.Spark ? 'me' : 'user'
        const response = await request<{
            id?: string,
            key?: string,
            firstname?: string,
            nickname?: string,
            name?: string,
            isSuperAdmin: boolean
            isSuperSupport: boolean
        }>({
            url: url,
            method: 'GET',
        });
        const user = {
            id: response?.id ?? response?.key ?? '',
            name: response?.firstname ?? response?.name ?? response?.nickname ?? '',
            isSuperAdmin: response?.isSuperAdmin ?? response?.isSuperSupport ?? false
        };
        return user.id ? user : undefined;
    }
};
