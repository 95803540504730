import React, {FC, useEffect, useState} from 'react';
import {axiosInstance, loginService} from 'services';
import {Apps, AppsUrls, AppUrlsTest, areQuestionsSupported} from 'enums';
import {storage} from "utils";
import {User} from "interfaces";
import {questionsStore, settingsStore} from "../stores";
import {statsStore} from "stores/statsStore";
import {useInterceptors} from "hooks";
import LogRocket from "logrocket";
import {Envs, EnvSetup} from "enums/Envs";
import * as amplitude from "@amplitude/analytics-browser";
import {IdleMonitor} from "components/IdleMonitor";

interface AuthContextType {
    isInitializing: boolean;
    user: User | undefined;
    app: Apps | undefined;
    isTest: boolean | undefined;
    env: EnvSetup | undefined;
    signin: (user: string, app: Apps, isTest: boolean) => Promise<string | undefined>;
    reset: () => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider: FC<{ children: React.ReactNode }> = ({children}) => {
    const [isInitializing, setIsInitializing] = useState(true)
    const [state, setState] = useState<{
        user: User | undefined;
        app: Apps | undefined,
        isTest: boolean | undefined,
        env: EnvSetup | undefined
    }>({
        user: undefined,
        app: undefined,
        isTest: false,
        env: undefined
    });

    const trackUser = (user: { id: string, name: string, isSuperAdmin: boolean }, app: Apps, isTest: boolean) => {
        amplitude.setUserId(user.id);
        const amplitudeIdentify = new amplitude.Identify();
        amplitudeIdentify.set('App', app);
        amplitudeIdentify.add('Session', 1);
        amplitudeIdentify.set('Test', isTest);
        amplitudeIdentify.set('SuperAdmin', user.isSuperAdmin);
        amplitudeIdentify.set('Name', user.name);
        amplitude.identify(amplitudeIdentify, {});
    }


    const signin = async (token: string, newApp: Apps, isTest: boolean) => {
        axiosInstance.defaults.baseURL = isTest ? AppUrlsTest[newApp] : AppsUrls[newApp];
        const response = await loginService.login(token, newApp);
        if (response.token && response.refresh && response.user) {
            storage.setToken(response.token);
            storage.setRefresh(response.refresh);
            storage.setUserId(response.user.id);
            storage.setApp(newApp)
            storage.setIsTest(isTest)
            setState({
                app: newApp,
                user: response.user,
                isTest,
                env: {env: isTest ? Envs.Test : Envs.Prod, app: newApp}
            });
            if (areQuestionsSupported(newApp)) {
                await questionsStore.loadQuestions();
            }
            trackUser(response.user, newApp, isTest)

            return response.user.id;
        }
    };

    const reset = () => {
        amplitude.reset();
        setState({app: undefined, user: undefined, isTest: undefined, env: undefined})
    }

    const {isInterceptorsConfigured} = useInterceptors(reset, state.app);

    useEffect(() => {
        const app = storage.getApp();
        const isTest = storage.getIsTest();
        setState({app, user: undefined, isTest, env: {env: isTest ? Envs.Test : Envs.Prod, app}});
    }, []);


    useEffect(() => {
        if (!isInterceptorsConfigured) {
            return
        }
        const app = storage.getApp();
        const isTest = storage.getIsTest();
        if (app) {
            axiosInstance.defaults.baseURL = isTest ? AppUrlsTest[app] : AppsUrls[app];
            loginService.getUser(app).then(async u => {
                const env = {env: isTest ? Envs.Test : Envs.Prod, app};
                if (u) {
                    setState({app, user: u, isTest, env});

                    if (!isTest) {
                        if (app === Apps.Instinct) {
                            LogRocket.init('lovemore/instinct');
                        } else if (app === Apps.Flava) {
                            LogRocket.init('lovemore/flava');
                        } else if (app === Apps.Bloom) {
                            LogRocket.init('lovemore/bloom-0f2rz');
                        }

                        LogRocket.identify(u?.id, {
                            name: u.name,
                            isSuperAdmin: u.isSuperAdmin
                        });
                    }

                    trackUser(u, app, isTest);
                } else {
                    amplitude.reset();
                }

                if (areQuestionsSupported(app)) {
                    await questionsStore.loadQuestions();
                }
                settingsStore.loadCountries(env)
                if (u?.isSuperAdmin) {
                    statsStore.loadAdmins(app)
                }
                setIsInitializing(false);
            })
        } else {
            setIsInitializing(false);
        }
    }, [state.app, isInterceptorsConfigured])


    let value = {...state, isInitializing: isInitializing || !isInterceptorsConfigured, signin, reset};

    return <AuthContext.Provider value={value}><IdleMonitor isSuperAdmin={state.user?.isSuperAdmin}
                                                            userId={state.user?.id} onIdle={reset}/>{children}
    </AuthContext.Provider>;
};
