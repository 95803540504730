import React, {useContext} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Layout, RequireAuth} from 'components';
import {LoginPage, ModerationPage, ProfileSearchByIDPage, SetupPage, TempUnbannedPage, VerificationsPage} from 'pages';
import {isSupportedRoute, RootRoutes} from 'enums';
import {AuthContext} from "./contexts";
import {ReviewedUsersPage} from "pages/Analysis/ReviewedUsers";
import {BlockedCountiesPage} from "pages/BlockedCounties/BlockedCounties";
import {ProfileSearchPage} from "pages/ProfileSearch/ProfileSearchPage";
import {AdminStatsTable} from "pages/Analysis/AdminStatsTable/AdminStatsTable";
import {AdminActivityProfilesPage} from "pages/AdminActivityProfiles/AdminActivityProfilesPage";
import {AutoBannedProfilesPage} from "pages/AutoBannedProfiles/AutoBannedProfilesPage";
import {AdminsPage} from "pages/Admins/AdminsPage";
import {AbuseStatsPage} from "pages/Analysis/AbuseStats/AbuseStatsPage";
import {ReviewStatsPage} from "pages/Analysis/ReviewStats/ReviewStatsPage";
import {AdminsStatsGraph} from "pages/Analysis/AdminsStatsGraph/AdminsStatsGraph";
import {UserStatsPage} from "pages/Analysis/UserStats";
import {AbuseWordsPage} from "pages/AbuseWords/AbuseWordsPage";

export const Router = () => {
    const {app, isInitializing, user} = useContext(AuthContext);
    const defaultRoute = isSupportedRoute(RootRoutes.Verifications, app) ? RootRoutes.Verifications : RootRoutes.Moderation;

    if (isInitializing) {
        return null;
    }

    return (
        <Routes>
            <Route element={<Layout/>}>
                <Route path="/" element={<Navigate replace to={defaultRoute}/>}/>
                {isSupportedRoute(RootRoutes.Moderation, app) &&
                    <Route
                        path={RootRoutes.Moderation}
                        element={
                            <RequireAuth>
                                <ModerationPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.Profile, app) &&
                    <Route
                        path={`${RootRoutes.Profile}/:userId`}
                        element={
                            <RequireAuth>
                                <ProfileSearchByIDPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.Profile, app) &&
                    <Route
                        path={`${RootRoutes.Profile}`}
                        element={
                            <RequireAuth>
                                <ProfileSearchByIDPage/>
                            </RequireAuth>
                        }
                    />}
                <Route
                    path={RootRoutes.AdminStatsGraph}
                    element={
                        <RequireAuth>
                            <AdminsStatsGraph/>
                        </RequireAuth>
                    }
                />

                <Route
                    path={RootRoutes.TempUnbanned}
                    element={
                        <RequireAuth>
                            <TempUnbannedPage/>
                        </RequireAuth>
                    }
                />
                <Route
                    path={RootRoutes.UsersStats}
                    element={
                        <RequireAuth>
                            <UserStatsPage/>
                        </RequireAuth>
                    }
                />
                {isSupportedRoute(RootRoutes.Setup, app) &&
                    <Route
                        path={RootRoutes.Setup}
                        element={
                            <RequireAuth>
                                <SetupPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.AdminStatsTable, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.AdminStatsTable}
                        element={
                            <RequireAuth>
                                <AdminStatsTable/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.AdminActivityProfiles, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.AdminActivityProfiles}
                        element={
                            <RequireAuth>
                                <AdminActivityProfilesPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.AbuseStats, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.AbuseStats}
                        element={
                            <RequireAuth>
                                <AbuseStatsPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.Admins, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.Admins}
                        element={
                            <RequireAuth>
                                <AdminsPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.AbuseWords, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.AbuseWords}
                        element={
                            <RequireAuth>
                                <AbuseWordsPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.AutoBanned, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.AutoBanned}
                        element={
                            <RequireAuth>
                                <AutoBannedProfilesPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.BlockedCounties, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.BlockedCounties}
                        element={
                            <RequireAuth>
                                <BlockedCountiesPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.Verifications, app) &&
                    <Route
                        path={RootRoutes.Verifications}
                        element={
                            <RequireAuth>
                                <VerificationsPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.ReviewedUsers, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.ReviewedUsers}
                        element={
                            <RequireAuth>
                                <ReviewedUsersPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.Search, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.Search}
                        element={
                            <RequireAuth>
                                <ProfileSearchPage/>
                            </RequireAuth>
                        }
                    />}
                {isSupportedRoute(RootRoutes.ReviewStats, app) && user?.isSuperAdmin &&
                    <Route
                        path={RootRoutes.ReviewStats}
                        element={
                            <RequireAuth>
                                <ReviewStatsPage/>
                            </RequireAuth>
                        }
                    />}
                <Route path={RootRoutes.Login} element={<LoginPage/>}/>
            </Route>
        </Routes>
    );
};
