import {
    convertToSearchBloomToModerationUser,
    convertToSearchFlavaToModerationUser,
    convertToSearchInstinctToModerationUser,
    convertToSearchSparkToModerationUser,
    request,
    SearchUserPayloadBloom,
    SearchUserPayloadFlava,
    SearchUserPayloadInstinct,
    SearchUserPayloadSpark,
} from 'services';
import {Apps, BanReasons, Genders, UserStatuses} from 'enums';
import {ModerationUser} from "interfaces";
import {
    createUserIdBanParam,
    createUserIdParam,
    createUserIdQueryParam,
    EnvSetup,
    getBanUrl,
    getSearchUrl,
    getSupportUrl
} from "enums/Envs";
import {eventTracker, ModerationSources} from "utils/eventTracker";

export interface SearchParams {
    name?: string
    searchMode?: 'Default' | 'AutoBan' | 'All'
    countryCode?: string
    maxBirthday?: Date
    gender?: number
    minBirthday?: Date
    mode?: number
    status?: UserStatuses
}

export interface DownloadedData {
    chats: DownloadedChat[]
}

interface DownloadedChat {
    matchId: string
    sparkerId: string
    chatMessages: DownloadedChatMessage[]
    messages: DownloadedChatMessage[]
    signals: DownloadedChatMessage[]
}

interface DownloadedChatMessage {
    date: string
    time: string
    subject: string
    message: string
    content: string
    isMyMessage: boolean
}

export interface UserChatMessage {
    date: string;
    isMyMessage: boolean,
    message: string
}

export interface UserChats {
    status: UserStatuses;
    key: string;
    messages: UserChatMessage[]
    name: string
}

export const usersService = {
    getReviewedPage: async (app: Apps, adminUserKey: string, skip: number, gender?: Genders) => {
        const genderParam = gender ? `gender=${+gender}` : 0;
        if (app === Apps.Instinct) {
            const response = await request<{ users: SearchUserPayloadInstinct[] }>({
                method: 'get',
                url: `/manage/reviewed-users?adminUserKey=${adminUserKey}&skip=${skip}&count=25&${genderParam}`,
            });

            return response?.users.map(
                u => convertToSearchInstinctToModerationUser(u)
            );
        } else if (app === Apps.Flava) {
            const response = await request<{ profiles: SearchUserPayloadFlava[] }>({
                method: 'get',
                url: `/manage/reviewed-users?adminUserKey=${adminUserKey}&skip=${skip}&count=25&${genderParam}`,
            });

            return response?.profiles.map(
                u => convertToSearchFlavaToModerationUser(u)
            );
        } else if (app === Apps.Bloom) {
            const response = await request<{ profiles: SearchUserPayloadBloom[] }>({
                method: 'get',
                url: `/manage/reviewed-users?adminUserKey=${adminUserKey}&skip=${skip}&count=25&${genderParam}`,
            });

            return response?.profiles.map(
                u => convertToSearchBloomToModerationUser(u)
            );
        } else if (app === Apps.Spark) {
            const response = await request<{ sparkers: SearchUserPayloadSpark[] }>({
                method: 'get',
                url: `/manage/reviewed-users?adminUserKey=${adminUserKey}&skip=${skip}&count=25&${genderParam}`,
            });

            return response?.sparkers.map(
                u => convertToSearchSparkToModerationUser(u)
            );
        }

        alert("getReviewedPage Not implemented")
    },
    getPage: async (env: EnvSetup, excludedUsers: string[], params?: SearchParams) => {
        let users: ModerationUser[] | undefined = []
        if (env.app === Apps.Bloom) {
            const response = await request<{ users: SearchUserPayloadBloom[] }>({
                method: 'post',
                url: `${getSupportUrl(env)}/${getSearchUrl(env)}`,
                data: {excludedUsers, ...params},
            });

            users = response?.users.map(
                u => convertToSearchBloomToModerationUser(u)
            );
        } else if (env.app === Apps.Spark) {
            const response = await request<{ sparkers: SearchUserPayloadSpark[] }>({
                method: 'post',
                url: `${getSupportUrl(env)}/${getSearchUrl(env)}`,
                data: {excludedUsers, ...params},
            });

            users = response?.sparkers.map(
                u => convertToSearchSparkToModerationUser(u)
            );
        } else if (env.app === Apps.Instinct) {
            const response = await request<{ users: SearchUserPayloadInstinct[] }>({
                method: 'post',
                url: `${getSupportUrl(env)}/${getSearchUrl(env)}`,
                data: {idsToRemove: excludedUsers, ...params},
            });

            users = response?.users.map(
                u => convertToSearchInstinctToModerationUser(u)
            );
        } else if (env.app === Apps.Flava) {
            const response = await request<{ profiles: SearchUserPayloadFlava[] }>({
                method: 'post',
                url: `${getSupportUrl(env)}/${getSearchUrl(env)}`,
                data: {excludeIds: excludedUsers, ...params},
            });

            users = response?.profiles.map(
                u => convertToSearchFlavaToModerationUser(u)
            );
        } else {
            alert("getPage Not implemented")
        }

        users?.forEach((user) => {
            user.photos?.forEach(photo => {
                const img = new Image();
                img.src = photo.image[0].link;
            })
        });
        return users;
    },
    getById: async (userKey: string, env: EnvSetup) => {
        if (env.app === Apps.Instinct) {
            const user = await request<SearchUserPayloadInstinct>({
                method: 'get',
                url: `${getSupportUrl(env)}/profile?userKey=${userKey}`,
            });

            return user ? convertToSearchInstinctToModerationUser(user) : null;
        } else if (env.app === Apps.Spark) {
            const user = await request<SearchUserPayloadSpark>({
                method: 'get',
                url: `${getSupportUrl(env)}/profile?userKey=${userKey}`,
            });

            return user ? convertToSearchSparkToModerationUser(user) : null;
        } else if (env.app === Apps.Flava) {
            const user = await request<SearchUserPayloadFlava>({
                method: 'get',
                url: `${getSupportUrl(env)}/profile?userKey=${userKey}`,
            });

            return user ? convertToSearchFlavaToModerationUser(user) : null;
        } else if (env.app === Apps.Bloom) {
            const user = await request<SearchUserPayloadBloom>({
                method: 'get',
                url: `${getSupportUrl(env)}/profile?userKey=${userKey}`,
            });

            return user ? convertToSearchBloomToModerationUser(user) : null;
        }
        alert("getById Not implemented!")
    },
    ban: async (userKey: string, reason: BanReasons, source: ModerationSources, isShadowBan: boolean, env: EnvSetup) => {
        if (env.app === Apps.Instinct || env.app === Apps.Flava || env.app === Apps.Spark) {
            return request({
                url: `${getSupportUrl(env)}/${getBanUrl(env)}`,
                method: 'put',
                data: {
                    ...createUserIdBanParam(env, userKey),
                    reason,
                    source: source === ModerationSources.Verification ? source : 'adminPanel',
                    isShadowBan
                },
            });
        } else {
            return request({
                url: `${getSupportUrl(env)}/ban`,
                method: 'put',
                data: {
                    matchKey: userKey,
                    reason,
                    source: source === ModerationSources.Verification ? source : 'adminPanel'
                },
            });
        }
    },
    unban: async (userKey: string, env: EnvSetup) => {
        if (env.app === Apps.Instinct || env.app === Apps.Flava || env.app === Apps.Spark) {
            return request({
                url: `${getSupportUrl(env)}/unban`,
                method: 'put',
                data: createUserIdBanParam(env, userKey),
            });
        } else if (env.app === Apps.Bloom) {
            return request({
                url: `${getSupportUrl(env)}/unban`,
                method: 'put',
                data: {matchKey: userKey},
            });
        } else {
            alert('Not implemented');
        }
    },
    temporaryUnban: async (userKey: string, env: EnvSetup) => {
        if (env.app === Apps.Instinct || env.app === Apps.Flava || env.app === Apps.Spark) {
            return request({
                url: `${getSupportUrl(env)}/temporary-unban`,
                method: 'put',
                data: createUserIdBanParam(env, userKey),
            });
        } else if (env.app === Apps.Bloom) {
            return request({
                url: `${getSupportUrl(env)}/temporary-unban`,
                method: 'put',
                data: {matchKey: userKey},
            });
        } else {
            alert('Not implemented');
        }
    },
    dislike: async (userKey: string, env: EnvSetup) => {
        if (env.app === Apps.Bloom) {
            return request({
                url: 'users/feedback',
                method: 'post',
                data: {userId: userKey, didLike: false},
            });
        } else if (env.app === Apps.Instinct || env.app === Apps.Flava || env.app === Apps.Spark) {
            return request({
                url: `${getSupportUrl(env)}/feedback`,
                method: 'put',
                data: createUserIdParam(env, userKey),
            });
        } else {
            alert("usersService.getPage Not implemented")
        }
    },
    update: async (
        data: { id: string; name: string; birthday: Date, gender: Genders },
        env: EnvSetup,
    ) => {
        eventTracker.track("update", {userKey: data.id, name: data.name, birthday: data.birthday})
        await request({
            url: `${getSupportUrl(env)}/user`,
            method: 'put',
            data: {
                ...createUserIdParam(env, data.id),
                nickname: data.name,
                birthday: data.birthday,
                gender: +data.gender
            },
        });

        return usersService.getById(data.id, env);
    },
    deletePhoto: async (
        {key, userKey}: { key: string[], userKey: string },
        env: EnvSetup,
    ) => {
        return request({
            url: `${getSupportUrl(env)}/user/photo?${createUserIdQueryParam(env)}=${userKey}&key=${key.join('&key=')}`,
            method: 'delete',
        });
    },
    updatePhoto: async (
        {key, userKey, isSensitive, hasFace}: { key: string, userKey: string, isSensitive: boolean, hasFace?: boolean },
        env: EnvSetup
    ) => {
        return request({
            url: `${env.app === Apps.Bloom || env.app === Apps.Spark ? `${getSupportUrl(env)}/user/photo` : `${getSupportUrl(env)}/user/photo`}`,
            method: 'put',
            data: {userKey, key, isSensitive, hasFace}
        });
    },
    deleteFeaturePhoto: async (
        {id, userKey}: { id: number, userKey: string },
        env: EnvSetup
    ) => {
        eventTracker.track("photo_feature_delete", {userKey, id})
        if (env.app === Apps.Flava) {
            return request({
                url: `${getSupportUrl(env)}/user/features/photo?userKey=${userKey}&id=${id}`,
                method: 'delete',
            });
        }

        alert("deleteFeaturePhoto Not implemented!")
    },
    postponeReview: async (env: EnvSetup, id: string) => {
        const url = `/${getSupportUrl(env)}/user/postpone-review`
        return await request({
            method: 'put',
            url: url,
            data: createUserIdParam(env, id)
        });
    },
    downloadProfile: async (id: string, env: EnvSetup) => {
        const url = `/${getSupportUrl(env)}/export`
        return await request<DownloadedData>({
            method: 'post',
            url: url,
            data: createUserIdParam(env, id)
        });
    },
    getAllChats: async (id: string, env: EnvSetup) => {
        const url = `/${getSupportUrl(env)}/user/${id}/chats`
        return await request<UserChats[]>({
            method: 'get',
            url: url,
            data: createUserIdParam(env, id)
        });
    },
    getChat: async (env: EnvSetup, params: { user1: string, user2: string, reportKey: string }) => {
        const url = `/${getSupportUrl(env)}/export/messages?authorKey=${params.user1}&targetKey=${params.user2}&complaintKey=${params.reportKey}`
        return await request<UserChatMessage[]>({
            method: 'get',
            url: url,
        });
    },
    giftSubscription: async (id: string, env: EnvSetup, periodInDays: number) => {
        const url = `/${getSupportUrl(env)}/user/gift-subscription`
        return await request({
            method: 'put',
            url: url,
            data: {userKey: id, periodInDays}
        });
    },
    gift: async (id: string, gift: string, env: EnvSetup, count: number) => {
        const url = `/${getSupportUrl(env)}/user/gift-${gift}`
        return await request({
            method: 'put',
            url: url,
            data: {userKey: id, count}
        });
    },
    delete: async (id: string, env: EnvSetup) => {
        const url = `/${getSupportUrl(env)}/user?userKey=${id}`
        return await request({
            method: 'delete',
            url
        });
    }
};
