import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from "contexts";
import {ModerationUser, VerificationUser} from "interfaces";
import {PendingCountsByGender, usersService, userStatsService, verificationsService} from "services";
import {ModerationUserCard} from "components";
import {Button} from "react-bootstrap";
import {VerificationInfo} from "pages/Verifications/components/VerificationInfo";
import {useVerificationProcessing} from "hooks/useVerificationProcessing";
import {Events, eventTracker, ModerationSources, ReviewTypes} from "utils/eventTracker";
import {UserStatuses} from "enums";
import {VerificationRejectReasons} from "enums/VerificationRejectReasons";


export const VerificationsPage: FC = () => {
    const hasMore = useRef(true);

    const {env} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<VerificationUser[]>([]);
    const [count, setCount] = useState<PendingCountsByGender | null>();
    const [currentIndex, setCurrentIndex] = useState(0);

    const verification = users[currentIndex];
    const user = verification?.user;
    const {openVerifyDialog} = useVerificationProcessing(env, user)

    const maybeLoadMore = async () => {
        if (!env) {
            return;
        }

        if (currentIndex + 10 === users.length && hasMore.current && !isLoading) {
            setIsLoading(true);
            const newUsers = (await verificationsService.getPage(
                env,
                users.length
            )) ?? [];
            hasMore.current = newUsers.length >= 20;
            setUsers(prev => [...prev, ...(newUsers ?? [])]);
            setIsLoading(false);
        }
    }

    const next = async () => {
        setCurrentIndex(pi => pi + 1)
        maybeLoadMore()
    };

    const back = async () => {
        setCurrentIndex(pi => pi - 1)
    };


    const verify = async (isAccepted: boolean) => {
        if (!env) {
            return;
        }
        setCount(prev => prev ? ({
            ...prev,
            premiumVerifications: user?.hasPremium ? prev.premiumVerifications - 1 : prev.premiumVerifications,
            verifications: user?.hasPremium ? prev.verifications : prev.verifications - 1
        }) : null)
        const result = await openVerifyDialog(isAccepted)
        if (result.isSuccess) {
            next()
        }
    }

    const handleUserChange = (u: ModerationUser) => {
        if (u.isBanned) {
            next()
        } else {
            setUsers(prev => prev.map(v => v.user.id === u.id ? {
                ...v,
                user: u
            } : v))
        }
    }

    const removeNeedsVerification = async () => {
        if (!env) {
            return
        }

        eventTracker.track(Events.review, {
            ...eventTracker.getReviewParams(user, ReviewTypes.Verification, ModerationSources.Verification),
            wasVerified: false,
        })
        await verificationsService.verify(env, user?.id, false, VerificationRejectReasons.Other);
        await usersService.unban(user.id, env);
        handleUserChange({...user, status: UserStatuses.Active})
        next()
    }

    useEffect(() => {
        if (!env) {
            return
        }
        setIsLoading(true);
        setTimeout(async () => {
            const newUsers = await verificationsService.getPage(env, 0);
            newUsers && setUsers(newUsers);
            setIsLoading(false);

            setCount(null)
            userStatsService.getPendingCount(env).then(setCount)
        }, 300);

    }, [env]);

    useEffect(() => {
        if (user?.id) {
            eventTracker.track(Events.profile_open, eventTracker.getReviewParams(user, null, ModerationSources.Verification))
        }
    }, [user?.id]);

    if (!user) {
        return <div className="w-100 align-items-center">
            {isLoading ? 'Загружается...' : 'Это все'}
        </div>
    }


    return <div className="m-2 m-lg-3">
        <ModerationUserCard
            header={<VerificationInfo verification={verification} count={count}/>}
            user={user}
            onUserChange={handleUserChange}
            hideVerificationPhoto={true}
            topButtons={<>
                {user.status === UserStatuses.NeedsVerification &&
                    <Button onClick={removeNeedsVerification}
                            variant="outline-dark">
                        Снять принудительную верификацию
                    </Button>
                }
            </>}
            buttons={<>
                <Button onClick={() => verify(false)} variant="danger">
                    Отклонить
                </Button>
                <Button
                    onClick={() => verify(true)}
                    variant="success"
                    className="mx-1">
                    Верифицировать
                </Button>
            </>}
            leftButtons={
                <Button disabled={currentIndex === 0} variant="secondary" className="mx-1"
                        onClick={back}>
                    Назад
                </Button>
            }
            canAskVerification={user.status !== UserStatuses.NeedsVerification}
            moderationSource={ModerationSources.Verification}
        />
    </div>

}
