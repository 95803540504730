import React, {FC, useContext, useEffect, useState} from "react";
import {AuthContext} from "contexts";
import {AbuseWord, abuseWordsService} from "services/abuseWordsService";
import {AgGridReact} from "ag-grid-react";
import {useAbuseWordsTable} from "pages/AbuseWords/useAbuseWordsTable";
import {useWindowDimensions} from "hooks";
import {AbuseWordModal} from "pages/AbuseWords/components/AbuseWordModal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {AbuseStats, statsService} from "services";

const MySwal = withReactContent(Swal)

export const AbuseWordsPage: FC = () => {
    const {env} = useContext(AuthContext);

    const [editingId, setEditingId] = useState<number | null>(null);
    const [deletingId, setDeletingId] = useState<number | null>(null);
    const [editingWord, setEditingWord] = useState<AbuseWord | null>(null);
    const [words, setWords] = useState<AbuseWord[]>([]);
    const [stats, setStats] = useState<AbuseStats[]>([]);

    const onDelete = async (id: number) => {
        setDeletingId(id)
    }

    const {columnDefs} = useAbuseWordsTable(setEditingId, onDelete);
    const {height} = useWindowDimensions()

    const onReset = (didChange?: boolean) => {
        setEditingId(null)
        if (didChange && env) {
            abuseWordsService.getAll(env).then(w => w && setWords(w));
            if (stats.length) {
                setWords(prevWords => prevWords.map(w => ({
                    ...w,
                    stats: stats.find(s => s.category === w.ruleName)
                })))
            }
        }
    }

    useEffect(() => {
        if (env) {
            abuseWordsService.getAll(env).then(async w => {
                if (w) {
                    setWords(w);
                    const stats = await statsService.getAbuse(env);
                    setStats(stats)
                    setWords(prevWords => prevWords.map(w => ({
                        ...w,
                        stats: stats.find(s => s.category === w.ruleName)
                    })))
                }
            });

        }
    }, [env]);

    useEffect(() => {
        const deleteWord = async () => {
            const word = words.find(w => w.id === deletingId);
            if (!deletingId || !word) {
                setDeletingId(null)
                return;
            }
            const result = await MySwal.fire({
                title: 'Удалить Abuse Паттерн?',
                text: `${word.ruleName}, Паттерн: ${word?.pattern}`,
                confirmButtonText: 'Да',
                confirmButtonColor: '#D9292E',
                cancelButtonText: 'Закрыть',
                showCancelButton: true,
                showConfirmButton: true
            })
            if (result.isConfirmed && env) {
                await abuseWordsService.delete(deletingId, env);
                abuseWordsService.getAll(env).then(w => {
                    if (w) {
                        setWords(w);
                        if (stats.length) {
                            setWords(prevWords => prevWords.map(w => ({
                                ...w,
                                stats: stats.find(s => s.category === w.ruleName)
                            })))
                        }
                    }
                });
            }
            setDeletingId(null)
        }
        deletingId && deleteWord()
    }, [words, deletingId]);


    useEffect(() => {
        if (editingId) {
            const word = words.find(w => w.id === editingId);
            word && setEditingWord(word)
        } else {
            setEditingWord(null)
        }
    }, [words, editingId]);

    return <div className="pt-2 px-1 d-flex flex-column align-items-end">
        <AbuseWordModal editingWord={editingWord} onReset={onReset}/>
        <div className="mt-2 w-100" style={{height: height - 150}}>
            <AgGridReact
                pagination={true}
                paginationPageSize={50}
                rowData={words}
                columnDefs={columnDefs}
            />
        </div>
    </div>
}